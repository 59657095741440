export class HTTPError extends Error {
  status: number

  constructor(message: string, status: number) {
    super(message)
    this.status = status
  }
}

export const logAndRethrow = (msg: string) => (error: Error) => {
  console.error(msg, error)
  throw error
}
