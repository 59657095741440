import { authFetch } from '@/utils/http'
import { SimpleTrackingEvent, WebAppTrackingEvent } from './types'
import { API_ROUTE } from '../backend/config'

const MINIMUM_BATCH_SIZE = 10
const BATCH_FLUSH_INTERVAL = 5000 // 5 seconds

export const sendTrackingEventsBatch = async (
  events: WebAppTrackingEvent[],
) => {
  authFetch(`${API_ROUTE}/analytics/track`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ events }),
    keepalive: true,
  }).catch((e) => {
    console.error('Error tracking event', e)
  })
}

let trackingEvents: WebAppTrackingEvent[] = []
let timeoutId: any = null

export const flushTrackingEventsBatch = () => {
  if (trackingEvents.length === 0) {
    return
  }

  const events = [...trackingEvents]
  sendTrackingEventsBatch(events)

  trackingEvents = []
}

const scheduleBatchSend = () => {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }

  if (trackingEvents.length >= MINIMUM_BATCH_SIZE) {
    flushTrackingEventsBatch()
    return
  }

  timeoutId = setTimeout(() => {
    flushTrackingEventsBatch()
  }, BATCH_FLUSH_INTERVAL)
}

export const trackEvent = (event: Omit<SimpleTrackingEvent, 'timestamp'>) => {
  trackingEvents.push({
    ...event,
    timestamp: new Date(),
    source: 'webapp',
  })

  scheduleBatchSend()
}
