import { TbError404 } from 'react-icons/tb'
import EmptyState from '../EmptyState'

const NotFoundPage = () => {
  return (
    <div className="mobile-layout">
      <EmptyState
        text="La página que estás buscando no existe."
        icon={<TbError404 />}
      />
    </div>
  )
}

export default NotFoundPage
