import { makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from '../../app/store'
import {
  checkAuth,
  login,
  logout,
  requestOTPCode,
  signup,
  verify,
  verifyOTPCode,
} from './api'
import { SignupData } from './types'
import { updateUserData } from '../tools/api'
import { toast } from 'react-toastify'

export class AuthStore {
  rootStore: RootStore
  token = undefined
  user: any = undefined

  requestingOTP = false
  requires2FA = false

  verifyingOTP = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  setUser(user: any) {
    if (!user) {
      this.user = undefined
      return
    }
    const formattedUser = {
      ...user,
      favoriteTools: user.favoriteTools?.map((t: any) => t.id) || [],
    }
    this.user = formattedUser
  }

  public async updateUserData(data: any) {
    this.user = { ...this.user, ...data }
    await updateUserData(this.user.id, data)
  }

  public async login(email: string, password: string) {
    const data = await login(email, password)
    if (data.token) {
      this.token = data.token
      this.setUser(data.user)
    }

    return data
  }

  public async logout() {
    await logout()
    this.token = undefined
    this.setUser(undefined)
  }

  public async signup(data: SignupData) {
    const result = await signup(data)
    if (result.token) {
      this.token = result.token
      this.setUser(result.user)
    }

    return result
  }

  public async checkAuth() {
    const data = await checkAuth()

    if (data.token) {
      this.token = data.token
      this.setUser(data.user)
    }

    return data
  }

  public async verify(token: string) {
    const data = await verify(token)
    return data
  }

  public async requestOTP() {
    this.requestingOTP = true

    try {
      const requires2FA = await requestOTPCode()

      if (requires2FA) {
        toast.success('Código enviado correctamente.')
      }

      runInAction(() => {
        this.requestingOTP = false
        this.requires2FA = requires2FA
      })
    } catch (error) {
      toast.error('No hemos podido enviar el código.')
      console.error(error)
    }
  }

  public async verifyOTP(otp: string) {
    this.verifyingOTP = true

    try {
      const isOTPValid = await verifyOTPCode(otp)

      if (!isOTPValid) {
        toast.error('Código incorrecto.')
      }

      runInAction(() => {
        this.verifyingOTP = false
        this.requires2FA = !isOTPValid
      })
    } catch (error) {
      toast.error('No hemos podido verificar el código.')
      console.error(error)
    }
  }
}
