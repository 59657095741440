import { authFetch, fetchJSON } from '@/utils/http'
import { API_ROUTE } from '../backend/config'
import { SignupData } from './types'
import { HTTPError } from '@/utils/error'

export const login = async (email: string, password: string) => {
  return await fetchJSON(`${API_ROUTE}/users/login`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ email, password }),
  })
}

export const logout = async () => {
  return await fetchJSON(`${API_ROUTE}/users/logout`, {
    method: 'POST',
    credentials: 'include',
  })
}

export const signup = async (signup: SignupData) => {
  return await fetchJSON(`${API_ROUTE}/users/signup`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(signup),
  })
}

export const checkAuth = async () => {
  return await fetchJSON(`${API_ROUTE}/users/me`, {
    credentials: 'include',
  })
}

export const forgotPassword = async (email: string) => {
  return await fetchJSON(`${API_ROUTE}/users/forgot-password`, {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
}

export const resetPassword = async (token: string, password: string) => {
  return await fetchJSON(`${API_ROUTE}/users/reset-password`, {
    method: 'POST',
    body: JSON.stringify({ token, password }),
  })
}

export const verify = async (token: string) => {
  return await fetchJSON(`${API_ROUTE}/users/verify/${token}`, {
    method: 'POST',
  })
}

export const getPrefilledUserData = async (token: string) => {
  return await fetchJSON(`${API_ROUTE}/users/prefilled/${token}`, {})
}

export const requestOTPCode = async () => {
  const response = await authFetch(`${API_ROUTE}/users/otp`, {
    method: 'POST',
  })

  if (!response.ok) {
    const errorText = await response.text()
    throw new HTTPError(errorText, response.status)
  }

  const requires2FA = response.status === 201

  return requires2FA
}

export const verifyOTPCode = async (otp: string) => {
  const response = await authFetch(`${API_ROUTE}/users/otp/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ otp }),
  })

  return response.ok
}
