import { useEffect } from 'react'
import { flushTrackingEventsBatch, trackEvent } from './api'

export const useTrackPageVisit = (eventKey: string, payload: any = {}) => {
  useEffect(() => {
    trackEvent({
      key: eventKey,
      data: payload,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useFlushTrackingEventsBeforeUnload = () => {
  useEffect(() => {
    const flushTrackingEvents = () => {
      flushTrackingEventsBatch()
    }

    window.addEventListener('beforeunload', flushTrackingEvents)

    return () => {
      window.removeEventListener('beforeunload', flushTrackingEvents)
    }
  }, [])
}
