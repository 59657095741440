import rootStore from '@/app/store'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

export const KitDigitalPaymentNotice = observer(() => {
  useKitDigitalToast()
  return null
})

function useKitDigitalToast() {
  const { auth, organizations } = rootStore
  const { planInfo } = organizations

  useEffect(() => {
    if (auth?.user?.role === 'org-manager') {
      if (!planInfo) runInAction(() => organizations.fetchPlan())
    }
    if (planInfo) {
      if (!planInfo.hasActiveSubscription && planInfo.stripePaymentLink) {
        toast(
          () => <KitDigitalToast paymentLink={planInfo.stripePaymentLink} />,
          {
            icon: () => '👋',
            style: {
              maxWidth: '60%',
              background: '#FEF3C7',
              color: '#92400E',
            },
          },
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planInfo, organizations])
}

interface Props {
  paymentLink: string
}

export function KitDigitalToast({ paymentLink }: Props) {
  const { auth } = rootStore
  const orgId = auth.user?.organization
  // const prefilledEmail = auth.user?.email
  const email = undefined

  const parameterizedPaymentLink = `${paymentLink}?client_reference_id=${orgId}&locale=es&prefilled_email=${email}`

  return (
    <div>
      <span>
        {
          <p>
            Bienvenido a AI Toolkit. Puedes activar tu subscripción (kit
            digital) en{' '}
            <a
              className="link"
              href={parameterizedPaymentLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              este enlace
            </a>
            .
          </p>
        }
      </span>
    </div>
  )
}
