import rootStore, { RootStore } from '@/app/store'
import { makeAutoObservable, runInAction } from 'mobx'
import { runChat, runTool } from '../tools/api'
import { MessageContentItem, Tool } from '../tools/types'
import { HTTPError } from '@/utils/error'
import { toast } from 'react-toastify'
import { trackEvent } from '../tracking/api'

type SendMessageOptions = {
  message: string
  files?: File[]
  systemMessageBuilder?: string
  expectStream?: boolean
}

export class ToolStore {
  rootStore: RootStore
  data?: Tool
  inputs: any = {}
  generating = false
  sendingMessage = false

  generationAbortController: AbortController | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  public setTool(tool: Tool) {
    this.data = tool
  }

  public setInputs(inputs: any) {
    this.inputs = inputs
  }

  public clearTool() {
    this.data = undefined
    this.inputs = {}
  }

  trackToolGeneration() {
    const { chat } = rootStore

    if (chat.thread._id) {
      trackEvent({
        key: 'form_tool_re_generation',
        data: {
          toolId: this.data!.id,
          threadId: chat.thread._id,
        },
      })
    } else {
      trackEvent({
        key: 'form_tool_generation',
        data: {
          toolId: this.data!.id,
        },
      })
    }
  }

  async generate(expectStream = true, isDemo = false) {
    this.generating = true
    const { chat } = rootStore

    // NOTE: Track before clearing the thread
    this.trackToolGeneration()

    await chat.clearThread()
    if (!isDemo) await chat.maybeCreateThread(this.data!.id)

    this.generationAbortController = new AbortController()

    try {
      const result = await runTool({
        tool: this.data!,
        signal: this.generationAbortController.signal,
        inputs: this.inputs,
        threadId: chat.thread._id,
        isDemo,
      })

      if (expectStream) {
        await chat.ingestMessageStream(result.body!)
      }
    } catch (err: any) {
      this.handleGenerationError(err)
    } finally {
      runInAction(() => {
        this.generating = false
      })
    }
  }

  async sendMessage({
    message,
    files,
    systemMessageBuilder,
    expectStream = true,
  }: SendMessageOptions) {
    this.generating = true
    const { chat } = rootStore
    chat.setSuggestions([])

    await chat.maybeCreateThread(this.data!.id)

    const userMessageContent: MessageContentItem[] = [
      { type: 'text', text: message },
    ]

    if (files && files.length > 0) {
      for (const file of files) {
        userMessageContent.unshift({
          type: 'file',
          filename: file.name,
        })
      }
    }

    chat.addMessage({
      role: 'user',
      content: userMessageContent,
    })

    this.generationAbortController = new AbortController()

    try {
      const response = await runChat({
        id: this.data!.id,
        prompt: message,
        signal: this.generationAbortController.signal,
        threadId: chat.thread._id,
        systemMessageBuilder: systemMessageBuilder,
        files,
      })

      const chatLimitReached =
        response.headers.get('X-Chat-Limit-Reached') === '1'

      // NOTE: Warning that the chat limit has been reached
      if (chatLimitReached) {
        toast.warn(
          'Has sobrepasado el límite de mensajes de este chat. Por favor inicia un nuevo chat.',
        )
      }

      if (expectStream) {
        // TODO: Andres charLimitReached in the new system
        // await chat.ingestMessageStream(response.body!, chatLimitReached)
        await chat.ingestMessageStream(response.body!)
      }
    } catch (err: any) {
      this.handleGenerationError(err)
    } finally {
      runInAction(() => {
        this.generating = false
      })
    }
  }

  public cancelGeneration() {
    if (this.generationAbortController) {
      this.generationAbortController.abort()
    }
  }

  handleGenerationError(err: any) {
    // Shallow abort errors
    if (err.name === 'AbortError') {
      return
    }

    if (err instanceof HTTPError) {
      if (err.status === 429 && err.message === 'Too many demo runs') {
        return toast.error(
          'Ha sobrepasado el límite de demostraciones. Regístrese para disfrutar de nuestras herramientas.',
        )
      } else if (err.status === 429) {
        return toast.error(
          'Ha sobrepasado el límite de peticiones. Póngase en contacto con nosotros para obtener más información.',
        )
      } else if (err.status === 415 && err.message === 'Document needs OCR') {
        return toast.error(
          'No hemos podido extraer texto del documento. Prueba con otro documento.',
        )
      }
      console.error(`HTTPError (${err.status}) generating: ${err.message}`)
    } else {
      console.error('Error generating:', err.message)
    }
    toast.error('Ha ocurrido un error. Por favor, intentelo más tarde.')
    this.generating = false
  }
}
