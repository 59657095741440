import { API_ROUTE } from '@/features/backend/config'
import { authFetch, fetchJSON, handleHTTPResponse } from '@/utils/http'
import { FileUpload, ThreadListItem, Tool } from './types'

interface ChatRunOpts {
  id: string
  prompt: string
  signal?: AbortSignal
  threadId?: string
  stream?: boolean
  systemMessageBuilder?: string
  files?: File[]
}

export const fetchTools = async () => {
  const result = await fetchJSON(`${API_ROUTE}/tools?limit=100`)
  return result.docs
}

export const fetchTool = async (id: string) => {
  return await fetchJSON(`${API_ROUTE}/tools/s/${id}`)
}

export const fetchDemoTool = async () => {
  return await fetchJSON(`${API_ROUTE}/tools/demo`)
}

export const fetchThread = async (id: string) => {
  const response = await fetchJSON(`${API_ROUTE}/tools/threads/${id}`)
  return response
}

export const createThread = async (toolId: string) => {
  return await fetchJSON(`${API_ROUTE}/tools/threads`, {
    method: 'POST',
    body: JSON.stringify({
      toolId: toolId,
    }),
  })
}

type ListThreadsResponse = {
  threads: ThreadListItem[]
  totalCount: number
  nextPageOffset: number | null
  previousPageOffset: number | null
  limit: number
}

export const listThreads = async (
  offset: number = 0,
  signal?: AbortSignal,
  query?: string,
): Promise<ListThreadsResponse> => {
  const queryParams = new URLSearchParams({
    limit: '20',
    offset: offset.toString(),
  })

  if (query) {
    queryParams.append('search', query)
  }

  return await fetchJSON(
    `${API_ROUTE}/tools/threads?${queryParams.toString()}`,
    { signal },
  )
}

export const softDeleteThread = async (id: string) => {
  return await authFetch(`${API_ROUTE}/tools/threads/${id}`, {
    method: 'DELETE',
  })
}

export const updateUserData = async (uid: string, data: any) => {
  return await fetchJSON(`${API_ROUTE}/users/${uid}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export const runChat = async ({
  id,
  prompt,
  signal,
  threadId,
  systemMessageBuilder,
  files,
  stream = true,
}: ChatRunOpts): Promise<Response> => {
  const formData = new FormData()
  formData.append('prompt', prompt)
  formData.append('threadId', threadId || '')
  formData.append('systemMessageBuilder', systemMessageBuilder || '')

  if (files && files.length > 0) {
    for (const file of files) {
      formData.append('files', file)
    }
  }

  const response = await authFetch(`${API_ROUTE}/tools/${id}/inference/chat`, {
    method: 'POST',
    body: formData,
    signal,
  })

  await handleHTTPResponse(response)

  if (!stream) {
    const result = await response.json()
    return result
  }

  return response
}

interface ToolRunOpts {
  tool: Tool
  inputs: any
  signal?: AbortSignal
  threadId?: string
  stream?: boolean
  isDemo?: boolean
}

export const runTool = async ({
  tool,
  inputs,
  signal,
  threadId,
  // stream = true,
  isDemo = false,
}: ToolRunOpts): Promise<Response> => {
  const endpoint = isDemo ? 'demo' : 'run'

  const fileUploadFields = Array.isArray(tool.toolFields)
    ? (tool.toolFields.filter(
        (f) => f.blockType === 'FileUpload',
      ) as FileUpload[])
    : []

  let body: FormData | string

  if (fileUploadFields.length > 0) {
    body = new FormData()

    body.append('inputs', JSON.stringify(inputs))
    body.append('threadId', threadId || '')

    for (const field of fileUploadFields) {
      const docs = inputs[field.name]

      if (!field.allowMultiple) {
        body.append(field.name, docs[0], docs[0].name)
        continue
      }

      // NOTE: Always a FileList
      if (docs instanceof FileList) {
        for (const file of docs) {
          body.append(field.name, file, file.name)
        }
      }
    }
  } else {
    body = JSON.stringify({ inputs, threadId })
  }

  const response = await authFetch(
    `${API_ROUTE}/tools/${tool.id}/inference/${endpoint}`,
    {
      method: 'POST',
      headers:
        body instanceof FormData
          ? undefined // NOTE: The Content-Type is set automatically by the browser
          : { 'Content-Type': 'application/json' },
      body,
      signal,
    },
  )

  await handleHTTPResponse(response)

  return response
}
