import { Link } from 'react-router-dom'

type UserNotActivePageProps = {
  isManager: boolean
}

const UserNotActivePage = ({ isManager }: UserNotActivePageProps) => {
  return (
    <div>
      <h1>Usuario desactivado.</h1>
      {isManager ? (
        <p>
          Contrata un plan desde la pagina de{' '}
          <Link to="/subscriptions">tus subscripciones</Link> y activa tu
          cuenta.
        </p>
      ) : (
        <p>
          Por favor contacta el administrador de tu organization para reactivar
          tu cuenta. Si tienes dudas contacta con AI Consortivm.
        </p>
      )}
    </div>
  )
}

export default UserNotActivePage
