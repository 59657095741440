const UserOutOfCreditsPage = () => {
  return (
    <div>
      <h1>Has alcanzado el limite mensual de tokens.</h1>

      <p>
        Ha sobrepasado el límite de peticiones. Póngase en contacto con AI
        Consortivm para obtener más información.
      </p>
    </div>
  )
}

export default UserOutOfCreditsPage
