import UserMenu from '@/components/auth/UserMenu'
import { observer } from 'mobx-react'
import { TbBrandWechat, TbClockRecord, TbSchool } from 'react-icons/tb'

import rootStore from '@/app/store'
import { getIcon } from '@/utils/icon'
import { Link, useLocation } from 'react-router-dom'
import logo from '../assets/images/ai-toolkit.svg'
import aic from '../assets/images/aic-logo2.svg'
import cx from 'classnames'

const Sidebar = () => {
  const { tools } = rootStore

  const location = useLocation()

  const toolsetItems = tools.toolsets
    .filter((toolset) => toolset.displayInSidebar)
    .map((toolset) => {
      const isActive = location.pathname.includes(`toolsets/${toolset.slug}`)
      return (
        <Link
          key={toolset.slug}
          className={cx('nav-item', { 'is-active': isActive })}
          to={`/toolsets/${toolset.slug}`}
        >
          {getIcon(toolset.icon)}
          {toolset.title}
        </Link>
      )
    })

  return (
    <div className="sidebar">
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="Logo AI Consortivm" />
        </div>
      </Link>
      <div className="nav">
        {/* <Link className="nav-item" to="/tools">
            <TbTools />
            Herramientas
            </Link> */}
        {toolsetItems}
        {/* <Link className="nav-item" to="/tools/chat-general">
            <TbBrandWechat />
            Chat general
            </Link> */}
        {/*  <a className="nav-item is-disabled" href="">
          <TbUsers />
          Colaboradores
        </a>
        <a className="nav-item is-disabled" href="">
          <TbClockRecord />
          Historial
        </a>
         */}

        <br />
        <br />
        {/* <Link className="nav-item" to="/resources">
            <TbSchool />
            Recursos
            </Link>
          */}

        <Link className="nav-item" to="/history">
          <TbClockRecord />
          Historial
        </Link>

        <Link className="nav-item" to="https://www.aiconsortivm.com/">
          <TbBrandWechat />
          Consultoría
        </Link>

        <a className="nav-item is-disabled" href="">
          <TbSchool />
          Recursos
        </a>
        {/* <a className="nav-item" href="">
            <TbLayout />
            Personalizado 1
            </a>
            <a className="nav-item" href="">
            <TbLayout2 />
            Personalizado 2
            </a> */}
      </div>
      <div className="settings">
        <UserMenu />
      </div>
      <div className="aic">
        <p>Powered by</p>
        <a href="https://www.aiconsortivm.com/" target="_blank">
          <img src={aic} alt="AI Consortivm" />
        </a>
      </div>
      <div className="toolkit-version">Versión: {APP_VERSION}</div>
    </div>
  )
}

export default observer(Sidebar)
