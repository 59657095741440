import { RootStore } from '@/app/store'
import { processEventStream } from '@/utils/stream'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { createThread, fetchThread } from '../tools/api'
import { Message, Suggestion, Thread } from '../tools/types'
import { makeEmptyThread } from './utils'

export class ChatStore {
  rootStore: RootStore
  thread: Thread
  loading?: boolean
  limitReached = false
  suggestions: Suggestion[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
    this.thread = makeEmptyThread()
  }

  public async loadThread(id?: string) {
    this.loading = true

    try {
      if (!id) {
        this.loadEmptyThread()
        return
      }

      // NOTE: Thread is already loaded
      if (this.thread._id === id) {
        return
      } else {
        // NOTE: Clear thread before loading a new one
        this.clearThread()
      }

      const thread = await fetchThread(id)

      runInAction(() => {
        this.thread = thread
      })

      return thread
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  public async loadEmptyThread() {
    this.thread = makeEmptyThread()
  }

  public async maybeCreateThread(toolId: string) {
    if (!this.thread._id) {
      const thread = await createThread(toolId)
      runInAction(() => {
        this.thread = thread
      })
    }
  }

  public async clearThread() {
    this.thread = makeEmptyThread()
    this.limitReached = false
  }

  public async addMessage(message: Message) {
    this.thread.messages = [...this.thread.messages, message]
  }

  async ingestMessageStream(stream: ReadableStream) {
    this.addMessage({
      role: 'assistant',
      content: [],
    })

    await processEventStream(
      stream,
      action((message: Message) => {
        this.thread.messages[this.thread.messages.length - 1] = message
      }),
    )
  }

  async setSuggestions(suggestions: Suggestion[]) {
    this.suggestions = suggestions
  }
}
