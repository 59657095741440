import { ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import rootStore from './store'
import { observer } from 'mobx-react'

export const SentryErrorBoundary = observer(
  ({ children }: { children: ReactNode }) => {
    const { auth, tools, tool, chat } = rootStore

    const enrichExceptionCapture = (scope: Sentry.Scope) => {
      if (auth.user) {
        scope.setUser({
          id: auth.user?.id,
          email: auth.user?.email,
        })

        scope.setTags({
          role: auth.user?.role,
          organization: auth.user?.organization?.id,
        })
      }

      scope.setContext('tools', {
        currentTool: tools.currentTool,
        lastVisitedToolset: tools.lastVisitedToolset,
      })

      scope.setContext('tool', {
        data: tool.data,
        inputs: tool.inputs,
        generating: tool.generating,
        sendingMessage: tool.sendingMessage,
      })

      if (chat.thread) {
        scope.setTag('thread', chat.thread._id)
      }
    }

    return (
      <Sentry.ErrorBoundary beforeCapture={enrichExceptionCapture}>
        {children}
      </Sentry.ErrorBoundary>
    )
  },
)
