import { useEffect } from 'react'
import { Route } from 'react-router-dom'
import rootStore from './store'
import UserNotActivePage from './pages/UserNotActivePage'
import UserOutOfCreditsPage from './pages/UserOutOfCreditsPage'
import NotFoundPage from '@/ui/error/NotFound'
import LinearProgress from '@mui/material/LinearProgress'

export const useAuthRoutes = () => {
  const { auth, organizations } = rootStore

  const userRole = auth.user?.role
  const isUserActive = auth.user?.active
  const chatTokensLeft = auth.user?.chatTokensLeft

  const isUserAdmin = userRole === 'admin'
  const isUserOrgManager = userRole === 'org-manager'

  useEffect(() => {
    if (isUserOrgManager) {
      organizations.fetchPlan()
    }
  }, [])

  if (
    isUserOrgManager &&
    (organizations.initialLoadingPlan || !organizations.planInfo)
  ) {
    return (
      <Route
        path="*"
        element={
          <div className="top-loading-bar">
            <LinearProgress />
          </div>
        }
      />
    )
  }

  if (!auth.user) {
    return null
  }

  return (
    <>
      {isUserOrgManager ? (
        <>
          <Route
            path="/subscriptions"
            lazy={() => import('./pages/SubscriptionsPage')}
          />
          <Route path="/stats" lazy={() => import('./pages/OrgStatsPage')} />
          <Route path="/api" lazy={() => import('./pages/APIDocPage')} />
          <Route
            path="/company-info"
            lazy={() => import('./pages/CompanyInfoPage')}
          />
        </>
      ) : null}

      {isUserOrgManager || isUserAdmin ? (
        <Route
          path="/knowledge-bases/*"
          lazy={() => import('./pages/KnowledgeBaseRoutes')}
        />
      ) : null}

      {isUserActive ? (
        chatTokensLeft > 0 ? (
          <>
            <Route
              path="/tools/:id/:threadId"
              lazy={() => import('./pages/ToolDetailPage')}
            />
            <Route
              path="/tools/:id"
              lazy={() => import('./pages/ToolDetailPage')}
            />
            <Route path="/tools" lazy={() => import('./pages/ToolListPage')} />
            <Route
              path="/toolsets/:slug"
              lazy={() => import('./pages/ToolListPage')}
            />
            <Route
              path="/resources"
              lazy={() => import('./pages/ResourcesPage')}
            />
            <Route path="/history" lazy={() => import('./pages/HistoryPage')} />
            <Route path="*" element={<NotFoundPage />} />
          </>
        ) : (
          <Route path="*" element={<UserOutOfCreditsPage />} />
        )
      ) : (
        <Route
          path="*"
          element={<UserNotActivePage isManager={isUserOrgManager} />}
        />
      )}
    </>
  )
}
