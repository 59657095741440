import { useEffect } from 'react'
import Sidebar from './Sidebar'
import { observer } from 'mobx-react'
import { useMediaQuery } from '@mui/material'
import { TbDeviceMobileOff } from 'react-icons/tb'
import EmptyState from './EmptyState'
import rootStore from '@/app/store'
import { Outlet, useLocation } from 'react-router-dom'
import { sendPageView } from '@/features/analytics/google'
/* import Notifications from '@/components/Notifications' */

const MainLayout = observer(() => {
  const mobile = useMediaQuery('(max-width:900px)')
  const location = useLocation()

  const { tools } = rootStore

  useEffect(() => {
    sendPageView(location.pathname, 'App')
  }, [location])

  useEffect(() => {
    tools.fetchToolsets()
  }, [tools])

  return mobile ? (
    <div className="mobile-layout">
      <EmptyState
        text="Esta página no soporta dispositivos móviles"
        icon={<TbDeviceMobileOff />}
      />
    </div>
  ) : (
    <>
      {/* <Notifications /> */}
      <div className="main-layout">
        <Sidebar />
        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  )
})

export default MainLayout
