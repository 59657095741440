export const triggerBlobDownload = (filename: string, blob: Blob) => {
  const blobUrl = window.URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = blobUrl
  a.download = filename.includes('/') ? filename.split('/').pop()! : filename

  // Append to the document body
  document.body.appendChild(a)

  // Programmatically click the anchor to trigger the download
  a.click()

  // Clean up
  window.URL.revokeObjectURL(blobUrl)
  document.body.removeChild(a)
}

export function downloadCSV(data: any[]) {
  const csvContent = data.join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  triggerBlobDownload('stats.csv', blob)
}
