export const priorityAndTitleSorter = (a: any, b: any) => {
  const priorityDifference = b.priority - a.priority
  if (priorityDifference !== 0) {
    return priorityDifference
  }
  const titleDifference = a.title?.localeCompare(b.title)
  if (titleDifference !== undefined) {
    return titleDifference
  }

  return 0
}
