import { HTTPError } from './error'

export const authFetch = async (path: string, opts: any = { headers: {} }) => {
  const response = await fetch(path, {
    credentials: 'include',
    ...opts,
    headers: {
      ...opts.headers,
    },
  })
  return response
}

export const fetchJSON = async (path: string, opts: any = { headers: {} }) => {
  const response = await authFetch(path, {
    ...opts,
    headers: {
      ...opts.headers,
      'Content-Type': 'application/json',
    },
  })
  await handleHTTPResponse(response)
  return await response.json()
}

export const handleHTTPResponse = async (response: Response) => {
  if (response.status !== 200) {
    const result = await response.json()
    throw new HTTPError(result.error, response.status)
  }
  return response
}
