import { observer } from 'mobx-react'

interface Props {
  text: string
  icon?: any
  className?: string
  dataCy?: string
}

const EmptyState = ({ text, icon, className, dataCy }: Props) => {
  return (
    <div className={`empty-state ${className}`} data-cy={dataCy}>
      <div className="empty-icon">{icon}</div>
      <p className="empty-text">{text}</p>
    </div>
  )
}

export default observer(EmptyState)
