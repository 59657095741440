import * as TablerIcons from 'react-icons/tb'
import * as GoIcons from 'react-icons/go'

export const getIcon = (icon?: string) => {
  const IconComponent = icon
    ? (TablerIcons as any)[icon] || (GoIcons as any)[icon]
    : null

  if (IconComponent) {
    return <IconComponent />
  } else {
    console.warn('[!] Icon not found', icon)
    return <TablerIcons.TbPalette />
  }
}
