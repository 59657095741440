import { makeAutoObservable, runInAction } from 'mobx'

import { RootStore } from '@/app/store'
import {
  KnowledgeBaseAPI,
  KnowledgeBaseDTO,
  KnowledgeBaseModel,
  OAuthFlowStartResult,
  PaginatedInternalDocuments,
  UpdateKnowledgeBaseModel,
} from './api'
import { toast } from 'react-toastify'
import { HTTPError } from '@/utils/error'

const LOCAL_STORAGE_CSRF_TOKEN_KEY = 'oauthCSRFToken'

export class KnowledgeBaseStore {
  rootStore: RootStore
  knowledgeBaseAPI: KnowledgeBaseAPI

  loadingStates: Record<string, boolean> = {}

  documentsBeingUploaded: Record<string, boolean> = {}
  documentsBeingRemoved: Record<string, boolean> = {}

  knowledgeBaseCache: Record<string, KnowledgeBaseModel> = {}

  oauthCSRFToken: string | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)

    this.knowledgeBaseAPI = new KnowledgeBaseAPI()
  }

  *fetchById(id: string) {
    this.loadingStates[id] = true

    const knowledgeBase: KnowledgeBaseModel = yield this.knowledgeBaseAPI
      .fetchById(id)
      .catch((error) => {
        if (error instanceof HTTPError && error.message.length > 0) {
          toast.error(error.message)
        } else {
          console.error('Error fetching knowledge base', error)
          toast.error(
            'Ha ocurrido un error inesperado. Por favor, intenta de nuevo.',
          )
        }
      })
      .finally(() => {
        runInAction(() => {
          this.loadingStates[id] = false
        })
      })

    this.knowledgeBaseCache[id] = knowledgeBase
  }

  *fetchDocuments(
    id: string,
    limit: number = 100,
    offset: number = 0,
    searchQuery?: string,
  ) {
    const paginatedDocuments: PaginatedInternalDocuments =
      yield this.knowledgeBaseAPI
        .fetchDocuments(id, limit, offset, searchQuery)
        .catch((error) => {
          if (error instanceof HTTPError && error.message.length > 0) {
            toast.error(error.message)
          } else {
            console.error('Error fetching documents', error)
            toast.error(
              'Ha ocurrido un error inesperado. Por favor, intenta de nuevo.',
            )
          }
        })

    return paginatedDocuments
  }

  *update(id: string, knowledgeBase: UpdateKnowledgeBaseModel) {
    yield this.knowledgeBaseAPI.update(id, knowledgeBase).catch((error) => {
      if (error instanceof HTTPError && error.message.length > 0) {
        toast.error(error.message)
      } else {
        console.error('Error updating knowledge base', error)
        toast.error(
          'Ha ocurrido un error inesperado. Por favor, intenta de nuevo.',
        )
      }
    })

    yield this.fetchById(id)
  }

  *uploadFile(id: string, file: File) {
    this.documentsBeingUploaded[file.name] = true

    yield this.knowledgeBaseAPI.uploadFile(id, file).finally(() => {
      delete this.documentsBeingUploaded[file.name]
    })
  }

  *downloadDocument(id: string, documentId: string, displayPath: string) {
    yield this.knowledgeBaseAPI.downloadDocument(id, documentId, displayPath)
  }

  *removeDocument(id: string, documentId: string) {
    this.documentsBeingRemoved[documentId] = true

    yield this.knowledgeBaseAPI.deleteDocument(id, documentId).finally(() => {
      delete this.documentsBeingRemoved[documentId]
    })
  }

  *startOAuthFlow(provider: string, kbId: string, redirectURI: string) {
    const { redirect_url, csrf_token }: OAuthFlowStartResult =
      yield this.knowledgeBaseAPI.connectOAuth2(provider, kbId, redirectURI)

    localStorage.setItem(LOCAL_STORAGE_CSRF_TOKEN_KEY, csrf_token)

    window.location.href = redirect_url
  }

  *completeOAuthStorageProviderChange(
    provider: string,
    redirectURI: string,
    query: Record<string, string>,
  ) {
    const csrfToken = localStorage.getItem(LOCAL_STORAGE_CSRF_TOKEN_KEY) ?? ''

    localStorage.removeItem(LOCAL_STORAGE_CSRF_TOKEN_KEY)

    const authorizedKb: KnowledgeBaseDTO =
      yield this.knowledgeBaseAPI.verifyOAuth2(
        provider,
        redirectURI,
        csrfToken,
        query,
      )

    return authorizedKb
  }
}
